import './scss/blocos_banners.scss';

import Vue from 'vue';

const listaBlocos = document.getElementsByClassName('payt-block-banners-container');
for (let blocoAtividdades of listaBlocos) {
    createVueBlocoBannerApp(blocoAtividdades);
}

function createVueBlocoBannerApp(node) {
    if(!node) {
        return;
    }
    new Vue({
        el: node,
        components: {},
        props: {},
        data() {
            return {
                scrollLeftOffset: 0,
                listenerConfigured: false,
                loadedImages: 0,
            };
        },
        computed:  {
            canScrollLeft() {
                return this.scrollLeftOffset > 3;
            },
            canScrollRight() {
                if(!this.listenerConfigured || this.loadedImages <= 0) {
                    return false;
                }
                const scrollSize = this.$refs.bannersContainer.scrollWidth;
                const width = this.$refs.bannersContainer.offsetWidth;
                return this.scrollLeftOffset < (scrollSize - width - 3);
            },
            scrollControlsTopPosition() {
                if(this.loadedImages <= 0) {
                    return -90;
                }
                return -1 * (Math.round(this.$refs.bannersContainer.offsetHeight / 2) + 20);
            }
        },
        mounted() {
            this.configureListeners();
        },
        methods: {
            configureListeners() {
                if(!this.listenerConfigured) {
                    this.$refs.bannersContainer.addEventListener('scroll', () => {
                        this.scrollLeftOffset = this.$refs.bannersContainer.scrollLeft;
                    });
                    this.listenerConfigured = true;
                }
            },
            scrollRight() {
                this.$refs.bannersContainer.scrollBy(this.$refs.bannersContainer.clientWidth, 0);
            },
            scrollLeft() {
                this.$refs.bannersContainer.scrollBy(-this.$refs.bannersContainer.clientWidth, 0);
            },
        },
    });    
}
